//权限过滤指令
import store from '@/store'

export default {
  mounted(el, {value}) {
    const {roleInfo, authorities} = store.getters
    if (typeof value === 'string') {
      value = [value];
    }
    //账套管理员
    if (!roleInfo.astAdmin && !value.every(val => authorities.includes(val))) {
      el.remove()
    }
  }
}
