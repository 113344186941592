<template>
  <div class="flex items-center justify-center h-full flex-column">
    <img :src="'/api'+webConfig.qrCode" alt="" class="w-150px h-150px">
    <div class="mt-8px">
      微信扫一扫，加客服微信
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";


export default {
  name: "KeFu",
  computed: {
    ...mapState(['webConfig']),
  },
}
</script>

<style scoped lang="less">
.version {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
</style>
