<template>
  <div class="p-20px text-16px leading-relaxed">
    <pre v-if="webConfig.about">{{ webConfig.about }}</pre>
    <template v-else>
      <p class="font-bold">用数据驱动运营。</p>
      <p class="font-bold">让每个决策都有数据支撑。</p>
      <p><b class="primary-color">云财税</b> 是一家专注于连锁企业 <b class="green-color">业务 - 财务</b>一体化数据智能服务商，以提升企业决策力和执行力为使命，为企业构建透明、快速、动态、智能的业财一体化专业能力。</p>
      <p>💁‍微信添加人工客服，获取私有化部署方案和源码购买方案</p>
    </template>
    <div class="version">
      <div>当前程序版本：v{{ VERSION }}</div>
      <div>
        构建日期：
        {{ dFormat(BUID_DATE, 'YYYY-MM-DD HH:mm:ss') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";


export default {
  name: "About",
  computed: {
    ...mapState(['webConfig']),
  },
}
</script>

<style scoped lang="less">
.version {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
</style>
